import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminService } from 'src/app/admin/admin.service';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { Feature, Permission } from 'src/app/model/user';
import { AlertService } from 'src/app/service/alert.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Business } from 'src/app/service/Business.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-add-edit-user-form',
  templateUrl: './add-edit-user-form.component.html',
  styleUrls: ['./add-edit-user-form.component.scss']
})
export class AddEditUserFormComponent implements OnInit {
  current_user: any;
  org_types = [{ id: '2', name: 'OEM' }, { id: '3', name: 'Food Manufacturer' }, { id: '7', name: 'Reseller' },
  { id: '4', name: 'Individual' }, { id: '6', name: 'My Win Organization' }];
  hierarchy_levels = [{ id: 'all', name: 'All Locations' }, { id: 'location', name: 'Location' }];
  user: any = {
    id: '', name: '', user_type: '', email_address: '', contact_number: '', designation: '', location: '',
    hierarchy_type: '', hierarchy_value: '',
    platform: 'website', ew_role: '', organization: null, subscription_enabled: false,
    contact_number_have_whatsapp: false, whatsapp_number: ''
  };
  ferror = {
    name: false, user_type: false, email_address: false, contact_number: false, designation: false,
    location: false, ew_role: false, organization: false, hierachy_value: false,
    org_user_slots_maxed: false, whatsapp_number: false
  };
  user_id = null;
  isUpdate = false;
  isClone = false;
  roles = [];
  countries = [];
  organizations = [];
  org_subscription = '';
  hierarchyValueList = [];
  org_groups = [];
  org_locations = [];
  selctedPhoneCode = '';
  is_view = false;
  userManageMentPermission: Permission;

  constructor(
    private adminService: AdminService,
    private businessService: Business,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private config: AppConfig,
    private confirmDialog: ConfirmDialogService,
    private authenticationService: AuthenticationService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.initPermissions();
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.route.queryParams.subscribe((params) => {
      this.user_id = params['id'];
      this.isClone = params['is_clone'];
      if (this.user_id) {
        this.is_view = true;
        this.adminService.getUserDetails(this.user_id).subscribe((resp) => {
          const _user: any = resp[0];
          if (this.isClone) {
            if (_user.user_type == "Individual") {
              this.user.user_type = '4';
            }
            if (_user.user_type == "OEM") {
              this.user.user_type = '2';
            }
            if (_user.user_type == "Food Manufacturer") {
              this.user.user_type = '3';
            }
            if (_user.user_type == "My Win User") {
              this.user.user_type = '6';
            }
            if (_user.user_type == "Reseller") {
              this.user.user_type = '7';
            }
            this.user.ew_role = _user.ew_role;
            this.user.designation = _user.designation;
            this.user.location = _user.location_alpha3;
            this.user.organization = _user.company;
            this.user.subscription_enabled = _user.subscription_enabled;
            this.user.hierarchy_type = _user.hierarchy_type;
            this.user.hierarchy_value = _user.hierarchy_value;
          } else {
            this.isUpdate = true;
            this.user.name = _user.contact_person_name;
            this.user.id = this.user_id;
            this.user.active = _user.active;
            this.user.is_subscribed = _user.is_subscribed;
            if (_user.user_type == "Individual") {
              this.user.user_type = '4';
            }
            if (_user.user_type == "OEM") {
              this.user.user_type = '2';
            }
            if (_user.user_type == "Food Manufacturer") {
              this.user.user_type = '3';
            }
            if (_user.user_type == "My Win User") {
              this.user.user_type = '6';
            }
            if (_user.user_type == "Reseller") {
              this.user.user_type = '7';
            }
            this.user.ew_role = _user.ew_role;
            this.user.email_address = _user.email_address;
            this.user.contact_number = _user.contact_number;
            this.user.designation = _user.designation;
            this.user.location = _user.location_alpha3;
            this.user.organization = _user.company;
            this.user.subscription_enabled = _user.subscription_enabled;
            this.user.hierarchy_type = _user.hierarchy_type;
            this.user.hierarchy_value = _user.hierarchy_value;
            this.user.contact_number_have_whatsapp = _user.contact_number_have_whatsapp;
            this.user.whatsapp_number = _user.whatsapp_number;
          }
          setTimeout(() => {
            this.onCountryChange();
          }, 100);

        });
      }
    });
    this.getAllRoles();
    this.getCountries();
    this.getOrgDropdownList();
  }

  async initPermissions() {
    const fm_permissions = await this.authenticationService.getUserPermissions();
    this.userManageMentPermission = fm_permissions.find(x => x.feature_name == Feature.UserManagement);
  }

  getAllRoles() {
    this.adminService.getAllRoles().subscribe((resp) => {
      this.roles = resp;
    }, (err) => this.alertService.error(err));
  }

  getCountries(): void {
    this.businessService.getAllCountries().subscribe((data) => {
      this.countries = data;
      this.onCountryChange();
    }, (err) => this.alertService.error(err));
  }

  getOrgDropdownList() {
    this.adminService.getOrganizationsDropdownList().subscribe((resp) => {
      this.organizations = resp;
      this.onOrganizationChange();
    })
  }

  onOrgTypeChange() {
    this.user.organization = null;
  }

  onOrganizationChange() {
    let org = this.organizations.find(x => x.id == this.user.organization);
    if (org) this.org_subscription = org?.subscription?.subscription_name;

    if (!this.isUpdate && this.user.user_type == '2') {
      this.adminService.checkUserSlotsAvailable(this.user.organization).subscribe((resp) => {
        this.ferror.org_user_slots_maxed = !resp?.flag;
        if (!resp?.flag) {
          this.alertService.error('Looks like user slots maxed out for the selected organization');
        }
      });
    }

    if (org?.groups) {
      this.org_groups = org.groups.map(x => {
        return { id: x.id.toString(), name: x.name }
      });
    }

    if (org?.locations) {
      this.org_locations = org.locations.map(x => {
        return { id: x.id.toString(), name: x.name }
      });
    }

    this.onHierarchyLevelChange();
  }

  onHierarchyLevelChange(from_ui = false) {
    if (from_ui) {
      this.user.hierarchy_value = '';
    }
    if (this.user.hierarchy_type == 'group') {
      this.hierarchyValueList = this.org_groups;
    }
    if (this.user.hierarchy_type == 'location') {
      this.hierarchyValueList = this.org_locations;
    }
    if (this.user.hierarchy_type == 'all') {
      // this.hierarchyValueList = [{ id: 'all', name: 'All' }];
      this.user.hierarchy_value = 'all';
    }
  }

  validate(): boolean {
    const _org_user_slots_maxed = this.ferror.org_user_slots_maxed;
    this.ferror = {
      name: false, user_type: false, email_address: false, contact_number: false, designation: false,
      location: false, ew_role: false, organization: false, hierachy_value: false, org_user_slots_maxed: _org_user_slots_maxed,
      whatsapp_number: false
    };

    if (!this.user.name) {
      this.ferror.name = true;
    }
    if (this.current_user.user_type == 'Admin') {
      if (!this.user.user_type) {
        this.ferror.user_type = true;
      }

      if (this.user.user_type == '2' || this.user.user_type == '3' || this.user.user_type == '7') {
        if (!this.user.organization) {
          this.ferror.organization = true;
        }
        if (!this.user.ew_role) {
          this.ferror.ew_role = true;
        }
      }
    } else {
      if (!this.user.ew_role) {
        this.ferror.ew_role = true;
      }
    }

    if (!this.user.designation) {
      this.ferror.designation = true;
    }
    if (!this.user.email_address) {
      this.ferror.email_address = true;
    } else if (!this.config.emailPattern.test(this.user.email_address)) {
      this.ferror.email_address = true;
    }
    if (!this.user.contact_number) {
      // this.ferror.contact_number = true;
    } else if (!this.config.phoneNumberPattern.test(this.user.contact_number)) {
      this.ferror.contact_number = true;
    }
    if (!this.user.location) {
      this.ferror.location = true;
    }

    if (this.user.user_type == '3' && (this.user.hierarchy_type == 'group' || this.user.hierarchy_type == 'location')) {
      if (!this.user.hierarchy_value) {
        this.ferror.hierachy_value = true;
      }
    }

    const eKeys = Object.keys(this.ferror);
    const isValid = eKeys.every((ek) => this.ferror[ek] == false);
    return isValid;
  }

  onCancel() {
    this.location.back();
  }


  onEditUser() {
    this.is_view = false;
  }

  onSave() {
    if (!this.validate()) return;

    if (!this.isUpdate) {
      this.adminService.addUser(this.user).subscribe((resp) => {
        this.alertService.success('User saved successfully', true);
        // this.router.navigate(['/admin/users/users-list'])
        this.location.back();
      }, (err) => this.alertService.error(err));
    } else {
      this.adminService.updateUserV2(this.user).subscribe((resp) => {
        this.alertService.success('User saved successfully', true);
        // this.router.navigate(['/admin/users/users-list'])
        this.location.back();
      }, (err) => this.alertService.error(err));
    }
  }

  onCountryChange() {
    this.selctedPhoneCode = '';
    const country: any = this.countries.find((item: any) => item.alpha3 == this.user.location);
    if (country && country.phone_code) {
      this.selctedPhoneCode = country.phone_code;
    }
  }

  onApproveUser() {
    this.adminService.approveSubscribedUser(this.user, this.user.user_type).subscribe((resp) => {
      this.alertService.success(resp.message || 'User approved successfully', true);
      // this.router.navigate(['/admin/users/users-list']);
      this.location.back();
    }, (err) => this.alertService.error(err));
  }

  onCloneUser() {
    this.isUpdate = false;
    this.isClone = true;
    this.user.name = '';
    this.user.email_address = '';
  }

  onRestetUserPwd() {
    this.confirmDialog.showConfirm(`Are you sure want to reset the user password."${this.user.email_address}" ?`)
      .pipe(first()).subscribe((status) => {
        if (status) {
          this.businessService.resetUserPassword(this.user.email_address)
            .subscribe((data) => {
              this.alertService.success(data.message, true);
            }, (err) => this.alertService.error(err));
        }
      });
  }

  onActiveToggle() {
    this.adminService.deactivateUser(this.user.id).subscribe((resp) => {
      this.alertService.success(resp.message || 'User status changed successfully', true);
      // this.router.navigate(['/admin/users/users-list']);
      this.location.back();
    }, (err) => this.alertService.error(err));
  }

}
