import { Injectable, Inject } from '@angular/core';
import { OemModule } from './oem.module';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../app.config';
import { filter, map } from 'rxjs/operators';
import { HelperService } from '../service/helper.service';
import { Business } from '../service/Business.service';
import { Data } from '../service/data.service';

@Injectable({
  providedIn: OemModule
})
export class OemService {
  baseUrl = '';
  formDataHeader = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
  last_solution_type = 'line';
  solution_form_isvisible = false;
  last_template = null;
  add_installation_form_data = null;
  add_installation_spare_parts_data = [];
  latest_created_spare_part = null;
  create_spare_part_from_linking = false;
  installation_spare_part_ids = [];
  edit_equipment = null;
  added_spare_parts = false;

  show_installation_toggle = true;
  show_sellable_toggle = true;
  opportunity_active_toggle = '1';
  project_active_toggle = false;
  opportunity_details_tab = false;

  layout_installation_equipment = null;

  myproject_stage_id = null;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private helper: HelperService,
    private business: Business,
    private dataService: Data
  ) {
    this.baseUrl = this.config.baseUrl;
  }

  getSubscribedFeatures() {
    let features = [];
    let _subcription = JSON.parse(sessionStorage.getItem('currentUser')).subscription;
    if (_subcription) {
      let modules = JSON.parse(_subcription.modules);
      let dochub_features = modules.find(x => x.name.toLowerCase() == 'dochub');
      if (dochub_features) {
        features = dochub_features.features;
      }
    }
    return features;
  }

  getSupplierEquipments(searchText, pageNumber, sortBy, endUser, country, primaryProduct, system, name, warranty,
    noInstallDetails: boolean): Observable<any> {
    const url = this.baseUrl + 'supplier/equipments/';
    const installation_detail = noInstallDetails ? 'false' : '';
    const _params = new HttpParams()
      .set('query', searchText)
      .set('page', pageNumber)
      .set('sort_by', sortBy)
      .set('system', system)
      .set('end_user', endUser)
      .set('primary_product', primaryProduct)
      .set('country', country)
      .set('name', name)
      .set('warranty', warranty)
      .set('installation_detail', installation_detail);

    return this.http.get<any>(url, { params: _params });
  }

  getSupplierTaggedEquipments(searchText, pageNumber, sortBy, endUser, orderNumber, supplier_org, reseller, status, origin): Observable<any> {
    if (reseller == null) reseller = '';
    const url = this.baseUrl + 'supplier/tagged_equipments/';
    const _params = new HttpParams()
      .set('search', searchText)
      .set('page', pageNumber)
      .set('ordering', sortBy)
      .set('fm_org__name', endUser)
      .set('order_number', orderNumber)
      .set('supplier_org', supplier_org)
      .set('reseller', reseller)
      .set('status', status)
      .set('tagged_by', origin);

    // return this.dataService.getData1(url, _params, true); // what happens on new item added and return to list
    return this.http.get<any>(url, { params: _params });
  }

  getAllSupplierTaggedEquipments(supplier_org): Observable<any> {
    const url = this.baseUrl + 'supplier/tagged_equipments/';
    const _params = new HttpParams()
      .set('all', 'true')
      .set('org_id', supplier_org);
    return this.http.get<any>(url, { params: _params });
  }

  getOEMAssignedInstalledEquipment(supplier_org, equipment_id): Observable<any> {
    const url = this.baseUrl + 'supplier/tagged_equipments/';
    const _params = new HttpParams()
      .set('assigned', 'true')
      .set('org_id', supplier_org)
      .set('equipment_id', equipment_id);
    return this.http.get<any>(url, { params: _params });
  }

  assignOEMInstallation(assign_details): Observable<any> {
    const url = `${this.baseUrl}supplier/assign_installed_equipment/`;
    return this.http.post(url, assign_details);
  }

  getSupplierEquipmentDetails(id): Observable<any> {
    const url = this.baseUrl + `supplier/tagged_equipments/?id=${id}`;
    return this.http.get<any>(url);
  }


  getSupplierEquipmentsFiltersData(query: string) {
    const url = this.baseUrl + 'supplier/filter_list_view/';
    const _params = new HttpParams().set('query', query);

    return this.http.get<any>(url, { params: _params });
  }

  getSupplierTagEquipmentsFiltersData(query: string, list_name) {
    const url = this.baseUrl + 'supplier/filter_tag_list_view/';
    const _params = new HttpParams().set('query', query)
      .set('list_name', list_name);

    return this.http.get<any>(url, { params: _params });
  }

  uploadDoucmenForEquipments(name, document, equipmentIds, document_label_ids): Observable<any> {
    const url = this.baseUrl + 'supplier/upload_documents/';
    const formData = new FormData();
    formData.append('name', name);
    formData.append('document', document);
    formData.append('installed_equipment_ids', equipmentIds);
    formData.append('document_label_ids', document_label_ids);

    return this.http.post(url, formData);
  }

  addPrimaryInfo(info, id): Observable<any> {
    const url = this.baseUrl + `supplier/installed_equipment_details/${id}/`;
    return this.http.put(url, info);
  }


  getProcessLinesTemplates(primaryProduct, searchText, pageNumber, sortBy): Observable<any> {
    const _params = new HttpParams()
      .set('primary_product', primaryProduct)
      .set('query', searchText)
      .set('page', pageNumber)
      .set('sort_by', sortBy);
    const url = this.baseUrl + 'admin/process_lines/';
    return this.http.get(url, { params: _params });
  }

  // solution apis...
  getSolutionDetails(solution_id, layouts = false): Observable<any> {
    let url = this.baseUrl + 'solution_details/' + solution_id + '/';
    if (layouts) url += '?layouts=true'
    return this.http.get<any>(url);
  }

  getSolutions(sort_by, search_text, primary_product, page_no, status, solution_type, solution_org = '', sellable): Observable<any> {
    const url = this.baseUrl + 'solution_list/';
    let _params = null;
    if (primary_product) {
      _params = new HttpParams()
        .set('ordering', sort_by)
        .set('search', search_text)
        .set('primary_products', primary_product)
        .set('page', page_no)
        .set('solution_type', solution_type)
        .set('sellable', sellable);
    } else {
      _params = new HttpParams()
        .set('ordering', sort_by)
        .set('search', search_text)
        .set('page', page_no)
        .set('solution_type', solution_type)
        .set('solution_org', solution_org)
        .set('sellable', sellable);
    }

    if (status != '') {
      _params = _params.append('is_public', status);
    }

    return this.http.get<any>(url, { params: _params });
  }

  addSolution(solution): Observable<any> {
    const url = this.baseUrl + 'solution_list/';
    return this.http.post<any>(url, solution);
  }

  updateSolution(solution): Observable<any> {
    const url = this.baseUrl + 'solution_details/' + solution.id + '/';
    return this.http.put<any>(url, solution);
  }

  deleteSolution(solution_id): Observable<any> {
    let url = this.baseUrl + 'solution_details/' + solution_id;
    return this.http.delete<any>(url);
  }

  updateSolutionSpareParts(solution): Observable<any> {
    const url = this.baseUrl + 'solution_details/' + solution.id + '/?spare_parts=true';
    return this.http.put<any>(url, solution);
  }

  getAllSolutions(org_id = '', param = 'all'): Observable<any> {
    const url = this.baseUrl + 'solution_org_list/';
    const _params = new HttpParams()
      .set('dropdown', 'true')
      .set('org_id', org_id);
    return this.http.get<any>(url, { params: _params });
  }

  getSellableSolutionsForDropdown(org_id): Observable<any> {
    const url = this.baseUrl + 'solution_org_list/';
    const _params = new HttpParams()
      .set('dropdown', 'true')
      .set('org_id', org_id)
      .set('sellable', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getAllSolutionsForLayout(org_id = '', param = 'all'): Observable<any> {
    const url = this.baseUrl + 'solution_org_list/';
    const _params = new HttpParams()
      .set('dropdown', 'true')
      .set('layout', 'true')
      .set('org_id', org_id);
    return this.http.get<any>(url, { params: _params });
  }

  getAllSolutionsByOrg(org_id): Observable<any> {
    const url = this.baseUrl + 'solution_org_list/';
    const _params = new HttpParams()
      .set('org_id', org_id);
    return this.http.get<any>(url, { params: _params });
  }

  //usecase
  getUsecase(solution_id = ''): Observable<any> {
    const url = this.baseUrl + 'usecase_list/';
    const _params = new HttpParams()
      .set('page', '0')
      .set('solution_id', solution_id);
    return this.http.get<any>(url, { params: _params });
  }

  addUsecase(usecase): Observable<any> {
    const url = this.baseUrl + 'usecase_list/';
    return this.http.post<any>(url, usecase);
  }

  updateUsecase(usecase): Observable<any> {

    const url = this.baseUrl + 'usecase_details/' + usecase.id + '/';
    return this.http.put<any>(url, usecase);
  }

  updateUseCaseROI(usecase): Observable<any> {
    const url = this.baseUrl + 'usecase_roi_details/';
    return this.http.put<any>(url, usecase);
  }

  getUsecaseDetails(use_case_id): Observable<any> {
    const url = this.baseUrl + 'usecase_details/' + use_case_id + '/';
    return this.http.get<any>(url);
  }

  deleteUsecase(use_case_id): Observable<any> {
    let url = this.baseUrl + 'usecase_details/' + use_case_id + '/';
    return this.http.delete<any>(url);
  }

  deleteFilterQuesids(ids): Observable<any> {
    let url = this.baseUrl + 'delete_filter_questions_ids/' + ids + '/';
    return this.http.delete<any>(url);
  }

  getAllInstallations(): Observable<any> {
    const url = this.baseUrl + 'installation_list/';
    const _params = new HttpParams()
      .set('all', 'true')
    return this.http.get<any>(url, { params: _params });
  }

  getSolutionInstallations(solution_id): Observable<any> {
    const url = this.baseUrl + `supplier/solution_installations/${solution_id}/`;
    return this.http.get<any>(url);
  }

  //opportunities apis
  getAllOpportunities(searchText, solFilter, selOrg, sort = '', page = 0, solution = '', reseller = '', manager = '',
    country_of_installation = '', is_archive): Observable<any> {
    if (reseller == null) reseller = '';
    if (manager == null) manager = '';
    if (country_of_installation == null) country_of_installation = '';
    const url = this.baseUrl + 'opportunity_list/';
    const _params = new HttpParams()
      .set('page', page.toString())
      .set('ordering', sort)
      .set('solution_org', selOrg)
      .set('solution_type', solFilter)
      .set('search', searchText)
      .set('solution', solution)
      .set('reseller', reseller)
      .set('manager', manager)
      .set('country_of_installation', country_of_installation)
      .set('is_archive', is_archive);
    return this.http.get<any>(url, { params: _params }).pipe(
      map(resp => {
        if (page) {
          resp.results.forEach(x => {
            x.opportunity_size_val = x.opportunity_size_val.replace(/\D/g, '').replace(/^0+/, '');
            if (x.closing_opp) {
              x.closing_opp[0].order_value = x.closing_opp[0].order_value.replace(/\D/g, '').replace(/^0+/, '');
            }
          });
        } else {
          resp.forEach(x => {
            x.opportunity_size_val = x.opportunity_size_val.replace(/\D/g, '').replace(/^0+/, '');
            if (x.closing_opp) {
              x.closing_opp[0].order_value = x.closing_opp[0].order_value.replace(/\D/g, '').replace(/^0+/, '');
            }
          });
        }
        return resp;
      })
    );
  }

  getAllCurrency(): Observable<any> {
    const url = this.baseUrl + 'get_currency/';
    return this.http.get<any>(url);
  }

  addOpportunities(opportunity): Observable<any> {
    const url = this.baseUrl + 'opportunity_list/';
    return this.http.post<any>(url, opportunity);
  }

  updateOpportunities(opportunity): Observable<any> {
    const _opportunity = JSON.parse(JSON.stringify(opportunity));
    if (_opportunity?.target_budget_value) {
      _opportunity.target_budget_value = _opportunity.target_budget_value.replace(/\D/g, '').replace(/^0+/, '');
    }
    if (_opportunity?.opportunity_size_val) {
      _opportunity.opportunity_size_val = _opportunity.opportunity_size_val.replace(/\D/g, '').replace(/^0+/, '');
    }
    if (_opportunity?.closing_opp?.order_value) {
      _opportunity.closing_opp.order_value = _opportunity.closing_opp.order_value.replace(/\D/g, '').replace(/^0+/, '');
    }
    const url = this.baseUrl + 'opportunity_details/' + _opportunity.id + '/';
    return this.http.put<any>(url, _opportunity);
  }

  deleteOpportunity(id): Observable<any> {
    const url = `${this.baseUrl}opportunity_details/${id}/`;
    return this.http.delete<any>(url);
  }

  getOpportunityDetails(opportunity_id): Observable<any> {
    const url = this.baseUrl + 'opportunity_details/' + opportunity_id + '/';
    return this.http.get<any>(url).pipe(map(x => {
      x.opportunity_size_val = x.opportunity_size_val.replace(/\D/g, '').replace(/^0+/, '');
      if (x.closing_opp[0]) {
        x.closing_opp[0].order_value = x.closing_opp[0].order_value.replace(/\D/g, '').replace(/^0+/, '');
      }
      return x;
    }));
  }

  generateOpportunityRFQForm(opportunity_id): Observable<any> {
    const url = this.baseUrl + 'supplier/opportunity_rfq/';
    const _params = new HttpParams()
      .set('opp_id', opportunity_id);
    return this.http.get<any>(url, { params: _params });
  }

  getOpportunityRFQDetails(opp_rfq_id): Observable<any> {
    const url = this.baseUrl + 'supplier/opportunity_rfq_details/' + opp_rfq_id + '/';
    return this.http.get<any>(url);
  }

  downloadOpportunityRFQDetails(opp_rfq_id): Observable<any> {
    const url = `${this.baseUrl}supplier/opportunity_rfq_details/${opp_rfq_id}/?download_excel=true`
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  downloadFile(blob: Blob) {
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = 'opportunity_rfq_details.xlsx';  // Set your filename here
    downloadLink.click();

    window.URL.revokeObjectURL(url);
  }

  updateOpportunityRFQDetails(opp_rfq): Observable<any> {
    const url = this.baseUrl + 'supplier/opportunity_rfq_details/' + opp_rfq.id + '/';
    return this.http.put<any>(url, opp_rfq);
  }

  updateOpportunityRFQPublishDate(opp_rfq_publish): Observable<any> {
    const url = this.baseUrl + 'supplier/opportunity_rfq_publish/';
    return this.http.put<any>(url, opp_rfq_publish);
  }

  updateOpportunityRFQQuoteSubmitDate(opp_rfq_submit): Observable<any> {
    const url = this.baseUrl + 'supplier/opp_rfq_quote_submit/';
    return this.http.put<any>(url, opp_rfq_submit);
  }

  // installation apis...
  getInstallationDetails(installation_id): Observable<any> {
    const url = this.baseUrl + 'installation_details/' + installation_id + '/';
    return this.http.get<any>(url);
  }

  getInstallations(sort_by, search_text, po_number, customer_org, supplier_org, page_no, solution = '', reseller = ''): Observable<any> {
    if (reseller == null) reseller = '';
    const url = this.baseUrl + 'installation_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('po_number', po_number)
      .set('supplier_org', supplier_org)
      .set('customer_org__name', customer_org)
      .set('page', page_no)
      .set('reseller', reseller)
      .set('solution', solution);

    // return this.dataService.getData1(url, _params, true) // what happens on new item added and return to list
    return this.http.get<any>(url, { params: _params });
  }

  addInstallation(installation): Observable<any> {
    const url = this.baseUrl + 'installation_list/';
    installation.processes = null;// JSON.stringify(installation.processes);
    const payload = JSON.stringify(installation);
    console.log(`Payload size: ${payload.length / 1024} KB`);
    console.log(installation);
    return this.http.post<any>(url, installation);
  }

  updateInstallation(installation): Observable<any> {
    const url = this.baseUrl + 'installation_details/' + installation.id + '/';
    installation.processes = null;// JSON.stringify(installation.processes);
    const payload = JSON.stringify(installation);
    console.log(`Payload size: ${payload.length / 1024} KB`);
    console.log(installation);
    return this.http.put<any>(url, installation);
  }

  deleteInstallation(installation_id): Observable<any> {
    const url = this.baseUrl + 'installation_details/' + installation_id + '/';
    return this.http.delete<any>(url);
  }

  deleteInstalledEquipment(ser_id): Observable<any> {
    const url = this.baseUrl + `installation_detach/${ser_id}/`;
    return this.http.delete<any>(url);
  }

  getallFMs(org_id) {
    let _params = new HttpParams()
      .set('org_id', org_id);
    const url = this.baseUrl + 'account/fm_list/';
    return this.http.get<any>(url, { params: _params }).pipe(
      map(fms => {
        fms.forEach((fm, i) => fm.row_id = i)
        return fms;
      })
    );
  }

  deleteSolutionDoc(document_id, solution_id): Observable<any> {
    const url = this.baseUrl + 'supplier/delete_document/solution/';
    const _params = new HttpParams()
      .set('document_id', document_id)
      .set('solution_id', solution_id)
    return this.http.delete<any>(url, { params: _params });
  }

  deleteInstallationDoc(document_id, solution_id): Observable<any> {
    const url = this.baseUrl + 'supplier/delete_document/installation/';
    const _params = new HttpParams()
      .set('document_id', document_id)
      .set('solution_id', solution_id)
    return this.http.delete<any>(url, { params: _params });
  }

  logDownloadFile(doc_id): Observable<any> {
    const url = this.baseUrl + 'admin/manual_log/?document_id=' + doc_id;
    return this.http.post<any>(url, null);
  }

  getOrganizationsList(sort_by, search_text, page_no): Observable<any> {
    const url = this.baseUrl + 'org_dropdown_list/';
    const _params = new HttpParams()
      .set('sort_by', sort_by)
      .set('query', search_text)
      .set('page', page_no)
      .set('user_type', '2');
    return this.http.get<any>(url, { params: _params });
  }

  getOEMOrgsDropList(): Observable<any> {
    const url = this.baseUrl + 'org_dropdown_list/';
    const _params = new HttpParams().set('user_type', '2').set('exclude_own', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getOemOrgsForOpportunityFilter(is_archive): Observable<any> {
    const url = this.baseUrl + 'supplier/opportunity_filter_oem_org_list/';
    const _params = new HttpParams()
      .set('is_archive', is_archive);
    return this.http.get<any>(url, { params: _params });
  }

  //myProject

  addMyProject(data): Observable<any> {
    const url = this.baseUrl + 'myproject_list/';
    return this.http.post<any>(url, data);
  }

  addMyProjectDetails(data): Observable<any> {
    const url = this.baseUrl + 'myproject_list/';
    return this.http.post<any>(url, data);
  }

  updateMyProjectDetails(details): Observable<any> {
    const url = this.baseUrl + 'myproject_details/' + details.id + '/';
    return this.http.put<any>(url, details);
  }

  getAllMyProject(searchText, pageNumber, sortBy, active_status, solution_org = '', solution = '', reseller = '', installed, closed): Observable<any> {
    if (reseller == null) reseller = '';
    const url = this.baseUrl + 'myproject_list/';
    if (installed) {
      const _params = new HttpParams()
        .set('search', searchText)
        .set('page', pageNumber)
        .set('ordering', sortBy)
        .set('project_status', active_status)
        .set('solution_org', solution_org)
        .set('solution', solution)
        .set('reseller', reseller)
        .set('installed', installed.toString())
        .set('closed', closed.toString());
      return this.http.get<any>(url, { params: _params });
    } else {
      const _params = new HttpParams()
        .set('search', searchText)
        .set('page', pageNumber)
        .set('ordering', sortBy)
        .set('project_status', active_status)
        .set('solution_org', solution_org)
        .set('solution', solution)
        .set('reseller', reseller)
        .set('closed', closed.toString());
      return this.http.get<any>(url, { params: _params });
    }
  }

  getMyProjectDetails(project_id): Observable<any> {
    const url = this.baseUrl + 'myproject_details/' + project_id + '/';
    return this.http.get<any>(url);
  }

  deleteMyProjectDetails(project_id): Observable<any> {
    const url = this.baseUrl + 'myproject_details/' + project_id + '/';
    return this.http.delete<any>(url);
  }

  closeProject(project_id): Observable<any> {
    const url = this.baseUrl + `supplier/project_closed/?id=${project_id}`;
    return this.http.put<any>(url, { id: project_id });
  }



  // spare_parts apis...
  getSparePartDetails(spare_part_id): Observable<any> {
    const url = this.baseUrl + 'spare_part_details/' + spare_part_id + '/';
    return this.http.get<any>(url);
  }

  getSpareParts(sort_by, search_text, part_number, part_name, vendor, page_no, spare_part_org = '', active_status = ''): Observable<any> {
    const url = this.baseUrl + 'spare_part_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('part_number', part_number)
      .set('part_name', part_name)
      .set('vendor__name', vendor)
      .set('page', page_no)
      .set('oem_organization', spare_part_org)
      .set('active_status', active_status);
    return this.http.get<any>(url, { params: _params });
  }

  addSparePart(spare_part): Observable<any> {
    const url = this.baseUrl + 'spare_part_list/';
    return this.http.post<any>(url, spare_part);
  }

  updateSparePart(spare_part): Observable<any> {
    const url = this.baseUrl + 'spare_part_details/' + spare_part.id + '/';
    return this.http.put<any>(url, spare_part);
  }

  deleteSparePart(spare_part_id): Observable<any> {
    const url = this.baseUrl + 'spare_part_details/' + spare_part_id + '/';
    return this.http.delete<any>(url);
  }

  getOEMVendors(page_no): Observable<any> {
    const url = this.baseUrl + `oem_vendors/?page=${page_no}`;
    return this.http.get<any>(url);
  }

  updateEquipmentSpareParts(equipment): Observable<any> {
    const url = this.baseUrl + 'supplier/equipment_spare_part/';
    return this.http.put<any>(url, equipment);
  }

  downloadSpareParts(id_list, sort_by, search_text, part_number, part_name, vendor, page_no): Observable<any> {
    const url = this.baseUrl + 'supplier/bulk_spare_parts_upload/?template=edit&download=y';
    const _params = new HttpParams()
      .set('id_list', id_list)
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('part_number', part_number)
      .set('part_name', part_name)
      .set('vendor', vendor)
      .set('page', page_no);
    return this.http.get<any>(url, { params: _params });
  }

  downloadSparePartsTemplate(templateType: string, org_id = null): Observable<any> {
    const url = this.baseUrl + 'supplier/bulk_spare_parts_upload/?template=' + templateType + '&org_id=' + org_id.toString() + '&page=0';
    return this.http.get<any>(url)
  }

  uploadBulkSpareParts(upload_file: any, file_type: string, templateType: string): Observable<any> {
    const url = this.baseUrl + 'supplier/bulk_spare_parts_upload/?template=' + templateType;
    const formData: FormData = new FormData();
    formData.append(file_type, upload_file);
    return this.http.post<any>(url, formData);
  }

  getFilterElements(query: string): Observable<any> {
    const url = this.baseUrl + 'supplier/filter_spare_parts/?query=' + query;
    return this.http.get<any>(url)
  }

  uniqueSparePartVerification(part_number, vendor, vendor_name): Observable<any> {
    const url = this.baseUrl + 'supplier/unique_verification_spare_parts/';
    const _params = new HttpParams()
      .set('part_number', part_number)
      .set('vendor', vendor)
      .set('vendor_name', vendor_name)
    return this.http.get<any>(url, { params: _params });
  }

  deleteSparePartVerification(spare_part_id: string): Observable<any> {
    const url = this.baseUrl + 'supplier/delete_verification_spare_parts/?spare_part_id=' + spare_part_id;
    return this.http.get<any>(url)
  }

  // solution mnt checklist apis...
  getSolutionMntChecklistDetails(checklist_id): Observable<any> {
    const url = this.baseUrl + 'solution_mnt_checklist_details/' + checklist_id + '/';
    return this.http.get<any>(url);
  }

  getSolutionMntChecklist(sort_by, search_text, page_no, checklist_org = ''): Observable<any> {
    const url = this.baseUrl + 'solution_mnt_checklist/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('page', page_no)
      .set('oem_organization', checklist_org);
    return this.http.get<any>(url, { params: _params });
  }

  addSolutionMntChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'solution_mnt_checklist/';
    return this.http.post<any>(url, checklist);
  }

  updateSolutionMntChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'solution_mnt_checklist_details/' + checklist.id + '/';
    return this.http.put<any>(url, checklist);
  }

  deleteSolutionMntChecklist(checklist_id): Observable<any> {
    const url = this.baseUrl + 'solution_mnt_checklist_details/' + checklist_id + '/';
    return this.http.delete<any>(url);
  }

  getSolutionHealthChecklist(sort_by, search_text, page_no, checklist_org = ''): Observable<any> {
    const url = this.baseUrl + 'solution_health_checklist/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('page', page_no)
      .set('oem_organization', checklist_org);
    return this.http.get<any>(url, { params: _params });
  }

  getSolutionHealthChecklistDetails(checklist_id): Observable<any> {
    const url = this.baseUrl + 'solution_health_checklist_details/' + checklist_id + '/';
    return this.http.get<any>(url);
  }

  addSolutionHealthChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'solution_health_checklist/';
    return this.http.post<any>(url, checklist);
  }

  updateSolutionHealthChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'solution_health_checklist_details/' + checklist.id + '/';
    return this.http.put<any>(url, checklist);
  }

  deleteSolutionHealthChecklist(checklist_id): Observable<any> {
    const url = this.baseUrl + 'solution_health_checklist_details/' + checklist_id + '/';
    return this.http.delete<any>(url);
  }

  getInstallationChecklist(installation_id, supplier_equipment_id): Observable<any> {
    const url = `${this.baseUrl}installation_mnt_checklist/`;
    let _params = new HttpParams();
    if (installation_id) {
      _params = new HttpParams().set('installation_id', installation_id);
    }
    if (supplier_equipment_id) {
      _params = new HttpParams().set('supplier_equipment_id', supplier_equipment_id);
    }
    return this.http.get<any>(url, { params: _params });
  }

  getInstallationChecklistById(checklist_id): Observable<any> {
    const url = `${this.baseUrl}installation_mnt_checklist/`;
    let _params = new HttpParams().set('checklist_id', checklist_id);
    return this.http.get<any>(url, { params: _params });
  }

  addInstallationMntChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'installation_mnt_checklist/';
    return this.http.post<any>(url, checklist);
  }

  updateInstallationMntChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'installation_mnt_checklist/';
    return this.http.put<any>(url, checklist);
  }

  deleteInstallationMntChecklist(checklist_id): Observable<any> {
    const url = this.baseUrl + 'installation_mnt_checklist/?checklist_id=' + checklist_id;
    return this.http.delete<any>(url);
  }

  getInstallationHealthChecklist(): Observable<any> {
    const url = `${this.baseUrl}installation_health_checklist/`;
    let _params = new HttpParams();
    return this.http.get<any>(url, { params: _params });
  }

  getInstallationHealthChecklistById(checklist_id): Observable<any> {
    const url = `${this.baseUrl}installation_health_checklist/`;
    let _params = new HttpParams().set('checklist_id', checklist_id);
    return this.http.get<any>(url, { params: _params });
  }

  addInstallationHealthChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'installation_health_checklist/';
    return this.http.post<any>(url, checklist);
  }

  updateInstallationHealthChecklist(checklist): Observable<any> {
    const url = this.baseUrl + 'installation_health_checklist/';
    return this.http.put<any>(url, checklist);
  }

  deleteInstallationHealthChecklist(checklist_id): Observable<any> {
    const url = this.baseUrl + 'installation_health_checklist/?checklist_id=' + checklist_id;
    return this.http.delete<any>(url);
  }

  getMaintenanceRecordInfo(sort_by, search_text, page_no, checklist_type, date, month, year): Observable<any> {
    const url = `${this.baseUrl}end_user/maintenance_record_info/`;
    let _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('page', page_no)
      .set('checklist_type', checklist_type)
      .set('date', date)
      .set('month', month)
      .set('year', year);
    return this.http.get<any>(url, { params: _params });
  }

  getMaintenanceRecordInfoById(checklist_entry_id, checklist_category): Observable<any> {
    const url = `${this.baseUrl}end_user/maintenance_record_info/`;
    let _params = new HttpParams()
      .set('checklist_entry_id', checklist_entry_id)
      .set('checklist_category', checklist_category);
    return this.http.get<any>(url, { params: _params });
  }

  getMaintenanceReportFilterSDataSource(query) {
    const url = `${this.baseUrl}end_user/maintenance_record_filter_data/`;
    let _params = new HttpParams().set('query', query);
    return this.http.get<any>(url, { params: _params });
  }

  checkSolutionModelNumberExists(model_number, org_id = ''): Observable<any> {
    const url = `${this.baseUrl}supplier/check_model_number/${model_number}/?oem_org_id=${org_id}`
    return this.http.get<any>(url);
  }

  getCapacityUOMs(): Observable<any> {
    const url = `${this.baseUrl}capacity_uom_list/?all=true`;
    return this.http.get<any>(url);
  }

  getDocumentLabels(): Observable<any> {
    const url = `${this.baseUrl}document_labels_list/?all=true`;
    return this.http.get<any>(url);
  }

  deleteSolutionImage(solution_id, file_name): Observable<any> {
    const url = `${this.baseUrl}file_skew/${solution_id}/solution/${file_name}`;
    return this.http.delete<any>(url);
  }

  downloadDocument(doc): any {
    this.http.post(`${this.baseUrl}azure_blob_url/`, { blob_name: doc.blob_name, download: true }, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = doc.file_name;
        link.click();
      });
  }

  // myPartner apis...
  // getSparePartDetails(spare_part_id): Observable<any> {
  //   const url = this.baseUrl + 'spare_part_details/' + spare_part_id + '/';
  //   return this.http.get<any>(url);
  // }

  // getSpareParts(sort_by, search_text, part_number, part_name, vendor, page_no, spare_part_org = '', active_status = ''): Observable<any> {
  //   const url = this.baseUrl + 'spare_part_list/';
  //   const _params = new HttpParams()
  //     .set('ordering', sort_by)
  //     .set('search', search_text)
  //     .set('part_number', part_number)
  //     .set('part_name', part_name)
  //     .set('vendor__name', vendor)
  //     .set('page', page_no)
  //     .set('oem_organization', spare_part_org)
  //     .set('active_status', active_status);
  //   return this.http.get<any>(url, { params: _params });
  // }
  getPartnerDetails(id): Observable<any> {
    const url = this.baseUrl + `mypartners_details/${id}/`;
    return this.http.get<any>(url);
  }

  getPartners(searchText): Observable<any> {
    const url = this.baseUrl + 'mypartners_list/';
    const _params = new HttpParams()
      .set('search', searchText);
    return this.http.get<any>(url, { params: _params });
  }

  addPartner(partner): Observable<any> {
    const url = this.baseUrl + 'mypartners_list/';
    return this.http.post<any>(url, partner);
  }

  updatePartner(partner): Observable<any> {
    const url = this.baseUrl + 'mypartners_details/' + partner.id + '/';
    return this.http.put<any>(url, partner);
  }

  deletePartner(id): Observable<any> {
    const url = this.baseUrl + 'mypartners_details/' + id + '/';
    return this.http.delete<any>(url);
  }

  checkUsernameExist(username: string) {
    const url = `${this.baseUrl}check_usernames/?username=${username}`;
    return this.http.get<any>(url);
  }

  getResellers() {
    const url = `${this.baseUrl}resellers_list/`;
    return this.http.get<any>(url);
  }

  getOEMs() {
    const url = `${this.baseUrl}oem_partners_list/`;
    return this.http.get<any>(url);
  }

  getOEMResellers(oem_org_id = null, _function, solution, country_of_installation) {
    if (oem_org_id == null) oem_org_id = '';
    const url = `${this.baseUrl}oem_reseller_list/`;
    let _params = new HttpParams()
      .set('oem_org', oem_org_id)
      .set('function', _function)
      .set('solution', solution)
      .set('country_of_installation', country_of_installation);
    return this.http.get<any>(url, { params: _params });
  }

  getResellerOEMs(module): Observable<any> {
    const url = `${this.baseUrl}reseller_oem_list/?module=${module}`;
    return this.http.get<any>(url).pipe(
      map(orgs => orgs.filter(org => org.module))
    );
  }

  checkOEMSubscription(org_id, module): Observable<any> {
    let _params = new HttpParams()
      .set('org_id', org_id)
      .set('module', module);
    const url = `${this.baseUrl}check_module_subscription/`;
    return this.http.get<any>(url, { params: _params });
  }

  checkPartnershipExists(oem_org, reseller_org, oem_username, reseller_username) {
    let _params = new HttpParams()
      .set('oem_org', oem_org)
      .set('reseller_org', reseller_org)
      .set('oem_username', oem_username)
      .set('reseller_username', reseller_username);
    const url = `${this.baseUrl}check_partners/`;
    return this.http.get<any>(url, { params: _params });
  }

  addUOM(name) {
    const url = `${this.baseUrl}capacity_uom_list/`;
    return this.http.post<any>(url, { uom_name: name });
  }

  checkResellerAddSlotsAvailable(org_id = null): Observable<any> {
    const url = `${this.baseUrl}check_oem_reseller_slot/?oem_org=${org_id}`;
    return this.http.get<any>(url);
  }

  checkResellerApproveSlotsAvailable(org_id = null): Observable<any> {
    const url = `${this.baseUrl}check_reseller_approve_slot/?oem_org=${org_id}`;
    return this.http.get<any>(url);
  }

  checkOrgWebsiteURLExist(website): Observable<any> {
    const url = `${this.baseUrl}check_org_website_exist/?website=${website}`;
    return this.http.get<any>(url);
  }

  getFMOrgLocations(fm_org_id, fm_org_request_id, org_id): Observable<any> {
    let _params = new HttpParams()
      .set('fm_org_id', fm_org_id)
      .set('org_id', org_id)
      .set('fm_org_request_id', fm_org_request_id);
    const url = `${this.baseUrl}account/fm_org_locations_list/`;
    return this.http.get<any>(url, { params: _params });
  }

  getAllDecisionMakers(fm_org, oem_org, fm_org_request): Observable<any> {
    let _params = new HttpParams()
      .set('fm_org', fm_org ? fm_org : '')
      .set('oem_org', oem_org ? oem_org : '')
      .set('fm_org_request', fm_org_request ? fm_org_request : '');
    const url = `${this.baseUrl}supplier/decision_makers_drop/`;
    return this.http.get<any>(url, { params: _params });
  }

  getAllDesignations(): Observable<any> {
    const url = `${this.baseUrl}designation_list/`;
    return this.http.get<any>(url);
  }

  addNewDecisionMaker(decision_maker): Observable<any> {
    const url = `${this.baseUrl}decision_makers_list/ `;
    return this.http.post<any>(url, decision_maker);
  }

  addNewDesignation(decision_maker): Observable<any> {
    const url = `${this.baseUrl}designation_list/ `;
    return this.http.post<any>(url, decision_maker);
  }

  getDecisionMakerDetails(id): Observable<any> {
    const url = `${this.baseUrl}decision_makers_details/${id}/ `;
    return this.http.get<any>(url);
  }

  updateDecisionMaker(decision_maker): Observable<any> {
    const url = `${this.baseUrl}decision_makers_details/${decision_maker.id}/ `;
    return this.http.put<any>(url, decision_maker);
  }

  updateDesignation(obj): Observable<any> {
    const url = `${this.baseUrl}decision_makers_details/${obj.id}/`;
    return this.http.put<any>(url, obj);
  }

  getAllCompetitors(oem_org): Observable<any> {
    let _params = new HttpParams()
      .set('oem_org', oem_org ? oem_org : '');
    const url = `${this.baseUrl}competitors_list/`;
    return this.http.get<any>(url, { params: _params });
  }

  addNewCompetitor(competitor): Observable<any> {
    const url = `${this.baseUrl}competitors_list/ `;
    return this.http.post<any>(url, competitor);
  }

  updateCompetitor(competitor): Observable<any> {
    const url = `${this.baseUrl}competitors_details/${competitor.id}/ `;
    return this.http.put<any>(url, competitor);
  }

  getOEMOpportunityManagers(oem_org_id): Observable<any> {
    let _params = new HttpParams()
      .set('oem_org_id', oem_org_id);
    const url = `${this.baseUrl}supplier/opportunity_managers_drop_down/`;
    return this.http.get<any>(url, { params: _params });
  }

  getOEMFilterOpportunityManagers(oem_org_id, is_archive): Observable<any> {
    let _params = new HttpParams()
      .set('oem_org_id', oem_org_id)
      .set('is_archive', is_archive);
    const url = `${this.baseUrl}supplier/opportunity_managers_filter_drop_down/`;
    return this.http.get<any>(url, { params: _params });
  }

  getOEMFilterOpportunityCountryOfInstallation(oem_org_id, is_archive): Observable<any> {
    let _params = new HttpParams()
      .set('oem_org_id', oem_org_id)
      .set('is_archive', is_archive);
    const url = `${this.baseUrl}supplier/opportunity_country_of_installation_filter_drop_down/`;
    return this.http.get<any>(url, { params: _params });
  }

  getOpportunityCompetitors(opp_id): Observable<any> {
    const url = `${this.baseUrl}supplier/opportunity_competitor_drop_down/?opportunity_id=${opp_id}`;
    return this.http.get<any>(url);
  }

  getSolutionsForOpportunityFilter(is_archive): Observable<any> {
    let _params = new HttpParams()
      .set('is_archive', is_archive);
    const url = `${this.baseUrl}supplier/opportunity_solutions_list/`;
    return this.http.get<any>(url, { params: _params });
  }

  getResellersForOpportunityFilter(is_archive): Observable<any> {
    let _params = new HttpParams()
      .set('is_archive', is_archive);
    const url = `${this.baseUrl}supplier/opportunity_reseller_list/`;
    return this.http.get<any>(url, { params: _params });
  }

  getFMTaggedEquipmentCount(oem_org_id = ''): Observable<any> {
    let _params = new HttpParams()
      .set('org_id', oem_org_id);
    const url = `${this.baseUrl}supplier/fm_tagged_equipment_count/`;
    return this.http.get<any>(url, { params: _params });
  }

  getOEMTaggedEquipmentCount(oem_org_id = ''): Observable<any> {
    let _params = new HttpParams()
      .set('org_id', oem_org_id);
    const url = `${this.baseUrl}supplier/oem_tagged_equipment_count/`;
    return this.http.get<any>(url, { params: _params });
  }

  getHealthChecklistEntryDetails(checklist_id, org_id): Observable<any> {
    const url = `${this.baseUrl}supplier/health_checklist_entry/`;
    let _params = new HttpParams().set('checklist_id', checklist_id).set('org_id', org_id);
    return this.http.get<any>(url, { params: _params });
  }

  updateHealthChecklistEntry(entry): Observable<any> {
    const url = `${this.baseUrl}supplier/health_checklist_entry/`;
    return this.http.put<any>(url, entry);
  }

  updateHealthChecklistFieldEntryFile(file): Observable<any> {
    const url = `${this.baseUrl}supplier/health_checklist_field_entry_file/`;
    return this.http.put<any>(url, file);
  }

  deleteHealthChecklistFieldEntryFile(file_id): Observable<any> {
    const url = `${this.baseUrl}supplier/health_checklist_field_entry_file/?file_id=${file_id}`;
    return this.http.delete<any>(url);
  }

  addEquipmentHealth(equipment_health) {
    const url = `${this.baseUrl}end_user/equipment_health_rating/`;
    return this.http.post(url, equipment_health);
  }

  getOrgNotificationConfigDetails(): Observable<any> {
    const url = `${this.baseUrl}supplier/org_notification_config_details/`;
    return this.http.get<any>(url);
  }

  updateOrgNotificationConfigDetails(notificationConfig): Observable<any> {
    const url = `${this.baseUrl}supplier/org_notification_config_details/`;
    return this.http.put<any>(url, notificationConfig);
  }
}
