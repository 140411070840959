import { PartnersComponent } from './partners/partners.component';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CKEditorModule } from 'ckeditor4-angular';

import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { OemRoutingModule } from './oem-routing.module';
import { OemComponent } from './oem.component';
import { TagsComponent } from './tags/tags.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SupplierProfileComponent } from './supplier-profile/supplier-profile.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { InstallationsComponent } from './installations/installations.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { SparePartsComponent } from './spare-parts/spare-parts.component';
import { AddEditSparePartsComponent } from './spare-parts/add-edit-spare-parts/add-edit-spare-parts.component';
import { SparePartsUploadComponent } from './spare-parts-upload/spare-parts-upload.component';
import { MaintenanceRecordInfoComponent } from './reports/maintenance-record-info/maintenance-record-info.component';
import { MaintainenceChecklistDetailsComponent } from './reports/maintenance-record-info/maintainence-checklist-details/maintainence-checklist-details.component';
import { EquipmentSolutionComponent } from './solutions/equipment-solution/equipment-solution.component';
import { ProcesstSolutionComponent } from './solutions/process-solution/process-solution.component';
import { LineSolutionComponent } from './solutions/line-solution/line-solution.component';
import { EquipmentInstallationComponent } from './installations/equipment-installation/equipment-installation.component';
import { ProcessInstallationComponent } from './installations/process-installation/process-installation.component';
import { LineInstallationComponent } from './installations/line-installation/line-installation.component';
import { EditProcessLayoutComponent } from './installations/edit-process-layout/edit-process-layout.component';
import { EditLineLayoutComponent } from './installations/edit-line-layout/edit-line-layout.component';
import { UsecaseComponent } from './solutions/usecase/usecase.component';
import { AddEditOpportunityComponent } from './opportunity/add-edit-opportunity/add-edit-opportunity.component';
import { OpportunityLayoutComponent } from './opportunity/opportunity-layout/opportunity-layout.component';
import { RoiComponent } from './solutions/usecase/roi/roi.component';
import { LayoutEquipmentDetailsComponent } from './installations/layout-equipment-details/layout-equipment-details';
import { ProjectsComponent } from './projects/projects.component';
import { SliderModule } from 'primeng/slider';
import { AddEditPartnerComponent } from './partners/add-edit-partner/add-edit-partner.component';
import { ProjectsLayoutComponent } from './projects/projects-layout/projects-layout.component';
import { AddEditProjectComponent } from './projects/add-edit-project/add-edit-project.component';
import { OemHomeComponent } from './oem-home/oem-home.component';
import { InstalledEquipmentDetailsComponent } from './installations/installed-equipment-details/installed-equipment-details.component';
import { StrategicMappingComponent } from './opportunity/strategic-mapping/strategic-mapping.component';
import { OppDashboardComponent } from './opportunity/opp-dashboard/opp-dashboard.component';
import { RfqComponent } from './solutions/usecase/rfq/rfq.component';
import { RfqFormComponent } from './opportunity/rfq-form/rfq-form.component';
import { DecisionMakerComponent } from './opportunity/decision-maker/decision-maker.component';
import { MyLearningOemComponent } from './my-learning-oem/my-learning-oem.component';
import { InsightsOemComponent } from './my-learning-oem/insights-oem/insights-oem.component';
import { InsightDetailsOemComponent } from './my-learning-oem/insights-oem/insight-details-oem.component';
import { KnowledgeSeriesOemComponent } from './my-learning-oem/knowledge-series-oem/knowledge-series-oem.component';
import { KnowledgeSeriesDetailsOemComponent } from './my-learning-oem/knowledge-series-oem/knowledge-series-details-oem.component';
import { OEMSupportRequestsComponent } from './installations/oem-support-requests/oem-support-requests.component';
import { OemOrgUsersComponent } from './users/oem-org-users.component';
import { ChecklistComponent } from './solutions/checklist/checklist.component';
import { ChecklistFormComponent } from './solutions/checklist/checklist-form/checklist-form.component';
import { HealthChecklistFormComponent } from './installations/health-checklist-form/health-checklist-form.component';
import { StepsModule } from 'primeng/steps';
import { NotificationConfigurationsComponent } from './notification-configurations/notification-configurations.component';


@NgModule({
  declarations: [
    OemComponent,
    TagsComponent,
    SupplierProfileComponent,
    SolutionsComponent,
    InstallationsComponent,
    SparePartsComponent,
    AddEditSparePartsComponent,
    SparePartsUploadComponent,
    MaintenanceRecordInfoComponent,
    MaintainenceChecklistDetailsComponent,
    EquipmentSolutionComponent,
    ProcesstSolutionComponent,
    LineSolutionComponent,
    EquipmentInstallationComponent,
    ProcessInstallationComponent,
    LineInstallationComponent,
    InstalledEquipmentDetailsComponent,
    EditProcessLayoutComponent,
    EditLineLayoutComponent,
    UsecaseComponent,
    OpportunityComponent,
    AddEditOpportunityComponent,
    AddEditProjectComponent,
    ProjectsLayoutComponent,
    PartnersComponent,
    AddEditPartnerComponent,
    ProjectsComponent,
    OpportunityLayoutComponent,
    RoiComponent,
    LayoutEquipmentDetailsComponent,
    OemHomeComponent,
    StrategicMappingComponent,
    OppDashboardComponent,
    RfqComponent,
    RfqFormComponent,
    DecisionMakerComponent,
    MyLearningOemComponent,
    InsightsOemComponent,
    InsightDetailsOemComponent,
    KnowledgeSeriesOemComponent,
    KnowledgeSeriesDetailsOemComponent,
    OEMSupportRequestsComponent,
    OemOrgUsersComponent,
    ChecklistComponent,
    ChecklistFormComponent,
    HealthChecklistFormComponent,
    NotificationConfigurationsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OemRoutingModule,
    PopoverModule.forRoot(),
    AngularDateTimePickerModule,
    SharedModule,
    DragDropModule,
    CdkScrollableModule,
    CKEditorModule,
    SliderModule,
    StepsModule,
  ],
  providers: [CurrencyPipe],
  // entryComponents: [ChecklistFormComponent]
})
export class OemModule { }
