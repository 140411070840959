<div class="row no-gutters page-head sticky-top bg-white">
    <div class="col-md-6 d-flex">
        <h4 class="head-title">Users - <span *ngIf="user_id">Edit</span><span *ngIf="!user_id">Add New</span> User</h4>
    </div>
    <div class="col-md-6 text-right w-value">
        <button type="button" class="btn btn-outline-secondary mr-2" (click)="onCancel()">Cancel</button>
        <button *ngIf="!is_view" type="button" class="btn btn-success mr-2" (click)="onSave()">Save</button>
        <ng-container *ngIf="current_user.user_type == 'Admin' || userManageMentPermission?.edit">
            <button *ngIf="is_view" class="btn btn-primary mr-2" (click)="onEditUser()">
                <span class="fa fa-pencil-square-o mr-2"></span> Edit
            </button>
        </ng-container>
        <ng-container *ngIf="isUpdate">
            <ng-container *ngIf="current_user.user_type == 'Admin' || userManageMentPermission?.approve">
                <button *ngIf="user.is_subscribed && !is_view" class="btn btn-primary mr-2" (click)="onApproveUser()">
                    <span class="fa fa-thumbs-o-up"></span> Approve
                </button>
            </ng-container>
            <ng-container *ngIf="current_user.user_type == 'Admin' || userManageMentPermission?.create">
                <button *ngIf="!is_view" class="btn btn-primary mr-2" (click)="onCloneUser()">
                    <span class="fa fa-clone"></span> Clone
                </button>
            </ng-container>
            <ng-container *ngIf="current_user.user_type == 'Admin' || userManageMentPermission?.edit">
                <button *ngIf="!is_view" class="btn btn-primary mr-2" (click)="onRestetUserPwd()">
                    <span class="fa fa-key"></span> Reset Password
                </button>
                <button *ngIf="!is_view" class="btn btn-primary mr-2" (click)="onActiveToggle()">
                    <span class="fa fa-user-times"></span>
                    <span *ngIf="user.active == 'Active'"> Deactivate</span>
                    <span *ngIf="user.active != 'Active'"> Activate</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="container-fluid p-0">
    <div class="layout-padding">
        <div class="card round-16 form-group">
            <div class="card-body">
                <div class="row form-group">
                    <div class="col-md-6 form-group">
                        <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="user.name"
                                [class.input-error]="ferror.name" [readOnly]="is_view">
                            <div *ngIf="ferror.name" class="text-danger fs-085">
                                Name is required
                            </div>
                        </div>
                        <div *ngIf="current_user.user_type == 'Admin'" class="form-group">
                            <label>Organization Type <span class="text-danger">*</span></label>
                            <app-ew-select [options]="org_types" [(ngModel)]="user.user_type" optionText="name"
                                optionValue="id" [read_only]="is_view" (change)="onOrgTypeChange()">
                            </app-ew-select>
                            <div *ngIf="ferror.user_type" class="text-danger fs-085">
                                Organization Type is required
                            </div>
                        </div>
                        <ng-container *ngIf="current_user.user_type == 'Admin'">
                            <div *ngIf="user.user_type != '6'" class="form-group">
                                <label>Organization <span class="text-danger">*</span></label>
                                <app-ew-select [options]="organizations | filter:user.user_type:'user_type'"
                                    [(ngModel)]="user.organization" optionText="name" optionValue="id"
                                    [read_only]="is_view" (change)="onOrganizationChange()">
                                </app-ew-select>
                                <div *ngIf="ferror.organization" class="text-danger fs-085">
                                    Organization is required
                                </div>
                                <div *ngIf="ferror.org_user_slots_maxed" class="text-danger fs-085">
                                    User slots reached to the maximum
                                </div>
                            </div>
                        </ng-container>
                        <div class="form-group">
                            <label>User Role <span class="text-danger">*</span></label>
                            <app-ew-select [options]="roles | filter:user.user_type:'user_type'"
                                [(ngModel)]="user.ew_role" optionText="name" optionValue="id" [read_only]="is_view">
                            </app-ew-select>
                            <div *ngIf="ferror.ew_role" class="text-danger fs-085">
                                User Role is required
                            </div>
                        </div>
                        <div *ngIf="user.user_type == '3' || current_user.user_type == 'Food Manufacturer'"
                            class="form-group">
                            <label>Hierarchy Level</label>
                            <select class="form-control" [(ngModel)]="user.hierarchy_type"
                                (change)="onHierarchyLevelChange(true)" [disabled]="is_view">
                                <option value="all">All Locations</option>
                                <option *ngIf="org_groups.length > 0" value="group">Group</option>
                                <option *ngIf="org_locations.length > 0" value="location">Location</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="user.email_address"
                                [disabled]="isUpdate" [class.input-error]="ferror.email_address" [readOnly]="is_view">
                            <div *ngIf="ferror.email_address" class="text-danger fs-085">
                                Valid Email is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Designation <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="user.designation"
                                [class.input-error]="ferror.designation" [readOnly]="is_view">
                            <div *ngIf="ferror.designation" class="text-danger fs-085">
                                Designation is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Country <span class="text-danger">*</span></label>
                            <app-ew-select [options]="countries" [(ngModel)]="user.location" optionText="name"
                                optionValue="alpha3" [read_only]="is_view" (change)="onCountryChange()">
                            </app-ew-select>
                            <div *ngIf="ferror.location" class="text-danger fs-085">
                                Country is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Contact Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{selctedPhoneCode}}</span>
                                </div>
                                <input type="text" class="form-control cont_bor" [(ngModel)]="user.contact_number"
                                    [class.input-error]="ferror.contact_number" [readOnly]="is_view">
                            </div>
                            <div *ngIf="ferror.contact_number" class="text-danger fs-085">
                                Please enter a valid contact number
                            </div>
                        </div>
                        <div class="form-check p-2 px-4">
                            <input type="checkbox" class="form-check-input" style="width: 18px;height: 18px;"
                                [(ngModel)]="user.contact_number_have_whatsapp" id="isWhatsAppNoSame"
                                [disabled]="is_view">
                            <label class="form-check-label pt-1" for="isWhatsAppNoSame">Use same number for
                                Whatsapp</label>
                        </div>
                        <div *ngIf="!user.contact_number_have_whatsapp" class="form-group">
                            <label>Whatsapp Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{selctedPhoneCode}}</span>
                                </div>
                                <input type="text" class="form-control cont_bor" [(ngModel)]="user.whatsapp_number"
                                    [class.input-error]="ferror.whatsapp_number" [readOnly]="is_view">
                            </div>
                            <div *ngIf="ferror.contact_number" class="text-danger fs-085">
                                Please enter a valid contact number
                            </div>
                        </div>
                        <div *ngIf="user.user_type == '3' && user.hierarchy_type != 'all'" class="form-group">
                            <label>Hierarchy Name <span class="text-danger">*</span></label>
                            <app-ew-select [options]="hierarchyValueList" [(ngModel)]="user.hierarchy_value"
                                optionText="name" optionValue="id" [read_only]="is_view">
                            </app-ew-select>
                            <div *ngIf="ferror.hierachy_value" class="text-danger fs-085">
                                Hierarchy Name is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>